<template>
  <div v-if="person && person.name">Nome: <b>{{ person.name }}</b></div>
</template>

<script>
export default {
  name: 'VisitorPersonName',
  props: {
    person: {}
  }
}
</script>
