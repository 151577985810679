<template>
  <th :style="theStyle">
    <div class="footer-cell-container" :class="theClass">
      <slot>&nbsp;</slot>
    </div>
  </th>
</template>

<script>
export default {
  name: 'FooterCell',
  props: {
    width: {
      default: 'auto'
    },
    align: {},
    bold: { Boolean }
  },
  computed: {
    theStyle () {
      return { width: this.width }
    },
    theClass () {
      const response = {
        bold: this.bold
      }

      if (this.align === 'right') {
        response['justify-content-end'] = true
      }

      if (this.align === 'center') {
        response['justify-content-center'] = true
      }

      return response
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-cell-container {
  display: flex;
  justify-content: flex-end;
  line-height: 1.25rem;

  &.bold {
    font-weight: 700;
    font-size: 1.1rem;
  }
}

th {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: #fff;
  padding: 0;
  border: none;

  > div {
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding: .75rem;
    border-left: none;
    border-right: none;
  }
}
</style>
