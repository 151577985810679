<template>
  <FooterRow>
    <FooterCell :colspan="colspan">
      <div class="d-flex justify-content-end gap">
        <div class="link text-primary" :class="{disabled: !prevCursor}" @click="$emit('cursor',prevCursor)">
          <i class="la la-angle-left"></i>
          <span>Anterior</span>
        </div>
        <div class="link text-primary" :class="{disabled: !nextCursor}" @click="$emit('cursor',nextCursor)">
          <span>Próxima</span>
          <i class="la la-angle-right"></i>
        </div>
      </div>
    </FooterCell>
  </FooterRow>
</template>

<script>
import FooterCell from '@/components/Table/FooterCell'
import FooterRow from '@/components/Table/FooterRow'
export default {
  name: 'FooterRowCursorPaginator',
  components: { FooterRow, FooterCell },
  props: {
    colspan: {},
    prevCursor: {},
    nextCursor: {}
  }
}
</script>

<style lang="scss" scoped>

</style>
