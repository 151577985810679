<template>
  <div>
    <hr>
    <div class="d-flex gap justify-content-center">
      <CoreButton class="lg" type="outline-primary" @click="$emit('click:cancel')">
        Cancelar
      </CoreButton>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import CoreButton from '@/components/CoreButton'

export default {
  name: 'VisitorFooterButtons',
  components: { CoreButton }
}
</script>
