<template>
  <div class="container">
    <Card title="Visitantes" subtitle="Cadastramento de visitantes">
      <ul class="nav nav-tabs nav-fill">
        <li class="nav-item">
          <span class="nav-link" :class="tabClass('pesq')" @click="showTab('pesq')">Pesquisar</span>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="tabClass('ident')" @click="showTab('ident')">Identificação</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="tabClass('docs')" @click="showTab('docs')">Envio de documentos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="tabClass('hist')" @click="showTab('hist')">Histórico de visitas</a>
        </li>
      </ul>
      <div class="tab-content">
        <div v-if="tab === 'pesq'">
          <div class="d-flex justify-content-center align-items-center gap">
            <CoreInput
              class="flex-grow-1"
              placeholder="Pesquise digitando aqui nome ou documento"
              :model-value.sync="search"
              @keyup.enter="load"
            />

            <CoreButton @click="add">
              <i class="la la-plus"></i>
              <span>Adicionar</span>
            </CoreButton>
          </div>
          <hr>
          <Table ref="table" size="lg" :loading="loading" :initial="initial" :empty="!rows.length" :fit-window="true">
            <HeaderRow>
              <!--              <HeaderCell width="50px"></HeaderCell>-->
              <HeaderCell width="50%">Nome</HeaderCell>
              <HeaderCell width="auto">Situação</HeaderCell>
              <HeaderCell width="auto">Cadastramento</HeaderCell>
              <HeaderCell width="auto">Último acesso</HeaderCell>
            </HeaderRow>
            <tr @click="rowClick(row)" v-for="row in rows" :key="row._id">
              <!--              <FixedCell>-->
              <!--                <i class="la la-ellipsis-v"></i>-->
              <!--              </FixedCell>-->
              <td>{{ row.name || '-' }}</td>
              <td class="text-center" :class="{'text-danger': !row.active}">
                {{
                  row.active ? 'Ativo' : 'Bloqueado'
                }}
              </td>
              <td class="text-center">{{ formatDateTime(row.created_at) }}</td>
              <td class="text-center">{{ formatDateTime(row.entered_at) }}</td>
            </tr>

            <FooterRowCursorPaginator
              colspan="4"
              :prev-cursor="prev_cursor"
              :next-cursor="next_cursor"
              @cursor="load($event)"
            />

            <template #initial>
              <div class="text-muted text-center py-5">
                <i class="la la-4x la-users"></i>
                <div class="text-muted">Utilize o campo de pesquisa acima para encontrar um cadastro</div>
                <div>ou clique no botão para criar um novo</div>
              </div>
            </template>
          </Table>

        </div>
        <div v-if="tab === 'ident'">
          <div class="d-flex gap">
            <div class="flex-grow-1">
              <CoreInput
                class="default-mb"
                label="Nome"
                required
                :model-value.sync="person.name"
                @blur="save()"
              />

              <CoreInput
                class="default-mb"
                label="Documento"
                :model-value.sync="person.doc_cpf"
                required
                @blur="save()"
              >
                <template #append>
                  <div class="input-group-append">
                    <div class="input-group-text">
                      {{ documentType(person.doc_cpf) }}
                    </div>
                  </div>
                </template>
              </CoreInput>

              <CoreSelect
                class="default-mb"
                label="Situação do acesso"
                v-model="person.active"
                @input="save()"
                :items="[{value: true, text: 'Ativo'},{value: false, text: 'Bloqueado'}]"
              />
            </div>

            <ImageEditor v-model="person.photo" @input="save()"/>
          </div>

          <div class="d-flex justify-content-end text-muted gap mt-3">
            <div v-if="person && person.created_at">Cadastramento feito em {{ formatDateTime(person.created_at) }}</div>
            <div v-if="person && person.updated_at">Última modificação {{ formatDateTime(person.updated_at) }}</div>
          </div>

          <VisitorFooterButtons @click:cancel="cancel()">
            <CoreButton class="lg" @click="showLinkCard()" data-toggle="dropdown">
              Vincular cartão de acesso
            </CoreButton>

            <div ref="linkCard" class="dropdown-menu">
              <div class="p-3" @click.stop>
                <div style="min-width: 300px">
                  <CoreSelect
                    class="default-mb"
                    label="Local de destino"
                    v-model="link_visitor.place_id"
                    text-field="name"
                    key-field="_id"
                    :items="places"
                  />

                  <CoreSelect
                    class="default-mb"
                    label="Número do cartão"
                    text-field="name"
                    key-field="_id"
                    :items="cards"
                    v-model="link_visitor.card_id"
                  />
                </div>

                <div class="dropdown-divider"></div>
                <CoreButton
                  @click="link"
                >Associar cartão
                </CoreButton>
              </div>
            </div>
          </VisitorFooterButtons>
        </div>
        <div v-show="tab === 'docs'">
          <VisitorPersonName :person="person"/>
          <VisitorFooterButtons @click:cancel="cancel()"/>
        </div>
        <div v-if="tab === 'hist'">
          <VisitorPersonName :person="person"/>
          <Table ref="historyTable" size="sm" :empty="!history.length" :fit-window="true">
            <HeaderRow>
              <HeaderCell class="text-left">Cartão de acesso</HeaderCell>
              <HeaderCell class="text-center">Local de destino</HeaderCell>
              <HeaderCell class="text-center">Dispositivo</HeaderCell>
              <HeaderCell class="text-center">Entrada</HeaderCell>
              <HeaderCell class="text-center">Saída</HeaderCell>
            </HeaderRow>
            <tr v-for="row of history" :key="row._id">
              <td>{{ lookupRelation(history_relations, 'cards', row.visitor_card_id, 'name') }}</td>
              <td>{{ lookupRelation(history_relations, 'places', row.visitor_place_id, 'name') }}</td>
              <td>{{ lookupRelation(history_relations, 'devices', row.device_id, 'name') }}</td>
              <td class="text-center">{{ formatDateTime(row.entered_at) }}</td>
              <td class="text-center">{{ formatDateTime(row.exited_at) }}</td>
            </tr>
          </Table>
          <VisitorFooterButtons @click:cancel="cancel()"/>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import Card from '@/components/Card'
import CoreInput from '@/components/CoreInput'
import CoreSelect from '@/components/CoreSelect'
import CoreButton from '@/components/CoreButton'
import Table from '@/components/Table'
import { formatDateTime } from '@/common'
import { H, setObj } from 'hennig-common'
import { showInfo } from '@/notifications'
import { generate as uuid } from 'ordered-uuid-v4'
import VisitorPersonName from '@/views/Visitor/VisitorPersonName'
import VisitorFooterButtons from '@/views/Visitor/VisitorFooterButtons'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import HeaderCell from '@/components/Table/HeaderCell'
import HeaderRow from '@/components/Table/HeaderRow'
import FooterRowCursorPaginator from '@/components/Table/FooterRowCursorPaginator'

export default {
  name: 'VisitorHome',
  components: {
    FooterRowCursorPaginator,
    HeaderRow,
    HeaderCell,
    VisitorFooterButtons,
    VisitorPersonName,
    Table,
    CoreButton,
    CoreSelect,
    CoreInput,
    Card
  },
  data () {
    return {
      tab: 'pesq',
      search: '',
      person: {},
      // Form para linkar os dados com um visitante
      cards: [],
      places: [],
      link_visitor: {
        place_id: '',
        card_id: ''
      },
      // ---
      loading: false,
      initial: true,

      rows: [],
      rows_relations: [],
      next_cursor: '',
      prev_cursor: '',

      history: [],
      history_relations: []
    }
  },
  watch: {
    async tab (name) {
      if (name === 'hist') {
        await this.$nextTick()
        H.rpc('Visitor', 'history', [this.person._id, this.$refs.historyTable.rowsPerPage()], r => {
          if (!r) return
          this.history = (r.data || [])
          this.history_relations = r.relations || []
        })
      }
    }
  },
  mounted () {
    this.loadCards()
    this.parseRoute(this.$route)
  },
  methods: {
    async parseRoute (route) {
      if (route.params.person_id) {
        // Load person if it is not here
        // this.person
        await this.get(route.params.person_id)
        if (this.person._id) {
          this.showTab('ident')
          return
        }
        this.home()
      }
    },
    link () {
      const data = {
        visitor_place_id: this.link_visitor.place_id,
        person_id: this.person._id,
        _id: this.link_visitor.card_id
      }
      H.rpc('Visitor', 'link', [data], r => {
        if (r) {
          showInfo('Cartão foi associado com o visitante')
          setTimeout(() => this.home(), 1000)
        }
      })
    },
    loadCards () {
      H.rpc('Visitor', 'cards', [], r => {
        this.cards = (r || [])
      })
      H.rpc('Visitor', 'places', [], r => {
        this.places = (r || [])
      })
    },
    documentType (v) {
      // '999.999.999-99',
      // '99.999.999/9999-99',
      if (cpf.isValid(v)) {
        return 'CPF'
      }

      if (cnpj.isValid(v)) {
        return 'CNPJ'
      }

      return 'Documento'
    },
    showLinkCard () {
      $(this.$refs.linkCard).dropdown()
    },
    get (_id) {
      return new Promise(resolve => {
        H.rpc('Visitor', 'get', [_id], r => {
          this.person = r || {}
          resolve()
        })
      })
    },
    load (cursor = '') {
      this.loading = true
      H.rpc('Visitor', 'search', [this.search, this.$refs.table.rowsPerPage(), cursor], r => {
        if (r) {
          this.rows = r.data || []
          this.rows_relations = r.relations || []
          this.next_cursor = r.next_cursor
          this.prev_cursor = r.prev_cursor
        }
        this.loading = false
        this.initial = false
      })
    },
    save () {
      H.rpc('Visitor', 'save', [this.person], r => {
        if (r) {
          setObj(this, this.person, r)
        }
      })
    },
    home () {
      this.$router.replace({ name: 'VisitorHome', params: {} }).catch(e => {
        if (e.name === 'NavigationDuplicated') {
          this.initial = true
          this.person = {}
          this.search = ''
          this.showTab('pesq')
        }
      })
    },
    add () {
      this.person = {
        _id: uuid(),
        active: true
      }
      this.showTab('ident')
    },
    cancel () {
      this.home()
    },
    rowClick (person) {
      this.$router.push({ name: 'VisitorHome', params: { person_id: person._id } })
    },
    tabClass (name) {
      // Pesquisar sempre ativa, nas outras precisamos ter uma pessoa
      return {
        disabled: name !== 'pesq' && (!this.person || !this.person._id),
        active: name === this.tab
      }
    },
    showTab (name) {
      this.tab = name
    },
    formatDateTime,
    lookupRelation (relations, collection, key, value) {
      return (relations && relations[collection] && relations[collection][key] && relations[collection][key][value]) || '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  padding-top: 2rem;
  padding-bottom: 1rem;

  ::v-deep .form-control {
    font-size: 1.5rem;
  }
}

::v-deep .CoreButton,
.CoreButton {
  height: 50px;

  &.lg {
    height: 100px;
    width: 100px;
  }
}
</style>
