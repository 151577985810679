<template>
  <div class="container">
    <Card title="Visitante" subtitle="Configurações do módulo de visitantes">
      <hr>
      <label>Lista de acesso onde novos visitantes serão inseridos</label>
      <div class="d-flex gap">
        <div class="w-50">
          <label>Evento</label>
          <EventSelect
            v-model="form.visitor_event_id"
          />
        </div>
        <div class="w-50">
          <label>Lista</label>
          <AccessListSelect
            :disabled="!form.visitor_event_id"
            :form-data="{ event_id: form.visitor_event_id }"
            v-model="form.visitor_accesslist_id"
          />
        </div>
      </div>
      <hr>
      <router-link :to="{name: 'VisitorPlaces'}">
        <i class="la la-external-link mr-1"></i><span>Locais de destino</span>
      </router-link>
      <hr>

      <router-link :to="{name: 'VisitorCards'}">
        <i class="la la-external-link mr-1"></i><span>Cartões de acesso</span>
      </router-link>
      <hr>

      <template #buttons>
        <div class="card-footer text-right">
          <CoreButton
            :loading="formSaving"
            :disabled="formSaving"
            @click="save()">Salvar
          </CoreButton>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import CoreButton from '@/components/CoreButton'
import { H } from 'hennig-common'

export default {
  name: 'VisitorSettings',
  components: {
    CoreButton,
    Card
  },
  data () {
    return {
      formSaving: false,
      form: {
        // Nomes usados no backend
        visitor_event_id: '',
        visitor_accesslist_id: ''
      }
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      H.rpc('Visitor', 'getData', [], r => {
        if (r) {
          this.form.visitor_event_id = r.visitor_event_id
          this.form.visitor_accesslist_id = r.visitor_accesslist_id
        }
      })
    },
    save () {
      this.formSaving = true
      H.rpc('Visitor', 'setData', [this.form], r => {
        this.formSaving = false
      })
    }
  }
}
</script>
